.page_title {

	padding: 0;
		text-shadow: 0 1px 2px #888;
	border-bottom: 5px solid $white;
	background: 	linear-gradient( -45deg, transparent 15%, rgba($white, 0.3) 50%, transparent 85% ),
					linear-gradient( -150deg, rgba(0,0,0,0.2) 20%, transparent 45%, transparent 55%, rgba(0,0,0,0.2) 80% ),
					theme(primary, base);
	color: $white;

	.image_wrap { padding: 15px 15px; }

	.page-title {
		margin: 0;
		font-weight: $b900;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
	}

	.title_wrap { padding: 0.75em 0; }

	@include media-breakpoint-up(md) {
		.image_wrap { padding-top: 45px; }
	}
	
	@include media-breakpoint-up(lg) {
		.image_wrap { padding-top: 90px; }
		.wrap { @include make-col(8); }
	}
	
}

.pt_img {

	@include media-breakpoint-up(md) {

		.image_wrap {
			max-width: 1200px;
			margin: 0 auto;
			background-image: contain;
			background-position: center center;
			background-image: url("/_/images/page-titles/service.jpg");
		}

		.title_wrap {
			//background-color: rgba(0,0,0,0.7);
			background-image: linear-gradient(to right, rgba(0,0,0,0.6) 40%, transparent);
		}

		&.page_services .image_wrap {  }
		&.service_design .image_wrap { background-image: url("/_/images/page-titles/design.jpg"); }
		&.service_install .image_wrap { background-image: url("/_/images/page-titles/installation.jpg"); }
		&.service_maintain .image_wrap { background-image: url("/_/images/page-titles/maintenance.jpg"); }
		
	}


}
