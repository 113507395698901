.slider_reviews {

	.slick-prev,
	.slick-next {
		background-color: $body-bg;
	}

	.slick-prev { background-image: url("/_/images/icons/slide_prev-review.png") }
	.slick-next { background-image: url("/_/images/icons/slide_next-review.png") }	



	@include media-breakpoint-up(lg) {

/*		.slick-prev,
		.slick-next {
			width: 44px;
			background-size: 100%;
		}
*/
	}

}