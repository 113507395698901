@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%; }

body {
  min-height: 100%;
  margin: 0;
  background-color: #f9f9f9;
  color: #343a40; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .wrap {
    padding-right: 0;
    padding-left: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

.wrap {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

.flex_container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 1em;
  box-sizing: border-box; }
  .flex_container [class*="col-"] {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding: 0 15px; }
  .flex_container.gutter-fix [class*="col-"] {
    padding-left: 0 !important; }
  @media (min-width: 576px) {
    .flex_container .col-sm-12 {
      width: 100%; }
    .flex_container .col-sm-11 {
      width: 91.66667%; }
    .flex_container .col-sm-10 {
      width: 83.33333%; }
    .flex_container .col-sm-9 {
      width: 75%; }
    .flex_container .col-sm-8 {
      width: 66.66667%; }
    .flex_container .col-sm-7 {
      width: 58.33333%; }
    .flex_container .col-sm-6 {
      width: 50%; }
    .flex_container .col-sm-5 {
      width: 41.66667%; }
    .flex_container .col-sm-4 {
      width: 33.33333%; }
    .flex_container .col-sm-3 {
      width: 25%; }
    .flex_container .col-sm-2 {
      width: 16.66667%; }
    .flex_container .col-sm-1 {
      width: 8.33333%; } }
  @media (min-width: 768px) {
    .flex_container .col-md-12 {
      width: 100%; }
    .flex_container .col-md-11 {
      width: 91.66667%; }
    .flex_container .col-md-10 {
      width: 83.33333%; }
    .flex_container .col-md-9 {
      width: 75%; }
    .flex_container .col-md-8 {
      width: 66.66667%; }
    .flex_container .col-md-7 {
      width: 58.33333%; }
    .flex_container .col-md-6 {
      width: 50%; }
    .flex_container .col-md-5 {
      width: 41.66667%; }
    .flex_container .col-md-4 {
      width: 33.33333%; }
    .flex_container .col-md-3 {
      width: 25%; }
    .flex_container .col-md-2 {
      width: 16.66667%; }
    .flex_container .col-md-1 {
      width: 8.33333%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .flex_container .col-md-break {
      width: 100%; } }
  @media (min-width: 992px) {
    .flex_container .col-lg-12 {
      width: 100%; }
    .flex_container .col-lg-11 {
      width: 91.66667%; }
    .flex_container .col-lg-10 {
      width: 83.33333%; }
    .flex_container .col-lg-9 {
      width: 75%; }
    .flex_container .col-lg-8 {
      width: 66.66667%; }
    .flex_container .col-lg-7 {
      width: 58.33333%; }
    .flex_container .col-lg-6 {
      width: 50%; }
    .flex_container .col-lg-5 {
      width: 41.66667%; }
    .flex_container .col-lg-4 {
      width: 33.33333%; }
    .flex_container .col-lg-3 {
      width: 25%; }
    .flex_container .col-lg-2 {
      width: 16.66667%; }
    .flex_container .col-lg-1 {
      width: 8.33333%; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .flex_container .col-lg-break {
      width: 100%; } }
  @media (min-width: 1200px) {
    .flex_container .col-xl-break {
      width: 100%; }
    .flex_container .col-xl-12 {
      width: 100%; }
    .flex_container .col-xl-11 {
      width: 91.66667%; }
    .flex_container .col-xl-10 {
      width: 83.33333%; }
    .flex_container .col-xl-9 {
      width: 75%; }
    .flex_container .col-xl-8 {
      width: 66.66667%; }
    .flex_container .col-xl-7 {
      width: 58.33333%; }
    .flex_container .col-xl-6 {
      width: 50%; }
    .flex_container .col-xl-5 {
      width: 41.66667%; }
    .flex_container .col-xl-4 {
      width: 33.33333%; }
    .flex_container .col-xl-3 {
      width: 25%; }
    .flex_container .col-xl-2 {
      width: 16.66667%; }
    .flex_container .col-xl-1 {
      width: 8.33333%; } }

@media (min-width: 768px) {
  .banner .banner_branding {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    z-index: 2; }
  .banner .banner_contact {
    padding-top: 40px;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .banner .banner_menu {
    flex: 0 0 75%;
    max-width: 75%;
    padding-left: 0;
    margin-top: -80px;
    margin-left: auto;
    z-index: 1; } }

@media (min-width: 992px) {
  .block_content .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .block_content .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 1200px) {
  .block_content .sidebar {
    padding-left: 10px; } }

@media (min-width: 992px) {
  .block_content.full_width .main {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .page_contact .main {
    flex: 0 0 50%;
    max-width: 50%; }
  .page_contact .sidebar {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (min-width: 992px) {
  .content.flip .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    order: 2; }
  .content.flip .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    order: 1; } }

@media (min-width: 576px) {
  .site_info .row {
    align-items: center;
    justify-content: center; }
  .site_info .wrap {
    flex: 0 0 50%;
    max-width: 50%; }
  .site_info .info_logo {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .site_info .wrap {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .site_info .info_company {
    order: 1; }
  .site_info .info_logo {
    flex: 0 0 25%;
    max-width: 25%;
    order: 2; }
  .site_info .info_copyright {
    order: 3; } }

@media (min-width: 992px) {
  .site_info .wrap {
    flex: 0 0 25%;
    max-width: 25%; } }

body {
  min-width: 360px;
  background-color: #f9f9f9; }
  @media (min-width: 768px) {
    body:not(.page_front) {
      height: 100%;
      padding-bottom: 145px; }
      body:not(.page_front) .site_info {
        position: absolute;
        bottom: 0;
        width: 100%; } }

#directions,
#hours {
  outline: none !important;
  box-shadow: none !important; }

html {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.725em;
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 768px) {
    html {
      font-size: 17px;
      font-size: 1.0625rem; } }

body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin: 30px 0 15px;
  font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: -0.0375em; }
  h4 small, .h4 small, h3 small, .h3 small, h2 small, .h2 small, h1 small, .h1 small {
    display: block;
    font-weight: 400; }

/*h6, .h6, %h6 {
	@extend %heading;
	@include font-size(17);
}

h5, .h5, %h5 {
	@extend %heading;
	@include font-size(18);
}*/
h4, .h4 {
  font-size: 18px;
  font-size: 1.125rem; }
  @media (min-width: 992px) {
    h4, .h4 {
      font-size: 20px;
      font-size: 1.25rem; } }

h3, .h3 {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700; }
  @media (min-width: 992px) {
    h3, .h3 {
      font-size: 24px;
      font-size: 1.5rem; } }

h2, .h2 {
  font-size: 24px;
  font-size: 1.5rem; }
  @media (min-width: 992px) {
    h2, .h2 {
      font-size: 28px;
      font-size: 1.75rem; } }

h1, .h1 {
  font-size: 30px;
  font-size: 1.875rem; }
  @media (min-width: 992px) {
    h1, .h1 {
      font-size: 36px;
      font-size: 2.25rem; } }

p {
  margin: 0 0 1em; }
  p.lead {
    font-size: 120%;
    line-height: 1.53em; }

ul, ol {
  margin: 0;
  padding: 0; }

b, strong {
  font-weight: 600; }

address {
  margin-bottom: 1em;
  font-style: normal; }

blockquote {
  margin: 0 0 1.5em;
  padding: 1em;
  font-size: 112.5%;
  line-height: 1.425em;
  border-left: 0.5rem solid; }
  blockquote > * {
    margin-bottom: 0.5em; }
  blockquote > *:last-child {
    margin-bottom: 0; }
  blockquote.bq--text {
    border-color: #343a40; }
  blockquote.bq--primary {
    border-color: #262262; }
  blockquote.bq--secondary {
    border-color: #ed1b5d; }
  blockquote.bq--highlight {
    border-color: #fd7e14; }
  blockquote.bq--accent {
    border-color: #F9BB4D; }
  blockquote.bq--success {
    border-color: #28a745; }
  blockquote.bq--danger {
    border-color: #dc3545; }
  blockquote.bq--warning {
    border-color: #d39e00; }
  blockquote.bq--info {
    border-color: #0182C8; }
  blockquote.review {
    padding: 0;
    margin: 0 0 1em;
    font-size: 1rem;
    border: none; }

.bq--title {
  font-weight: 800;
  letter-spacing: -0.0275em;
  text-transform: uppercase; }
  .bq--text .bq--title {
    color: #343a40; }
  .bq--primary .bq--title {
    color: #262262; }
  .bq--secondary .bq--title {
    color: #ed1b5d; }
  .bq--highlight .bq--title {
    color: #fd7e14; }
  .bq--accent .bq--title {
    color: #F9BB4D; }
  .bq--success .bq--title {
    color: #28a745; }
  .bq--danger .bq--title {
    color: #dc3545; }
  .bq--warning .bq--title {
    color: #d39e00; }
  .bq--info .bq--title {
    color: #0182C8; }

blockquote.bq-alt {
  margin-right: 5em;
  padding: 1.5em;
  border-radius: 6px;
  border: 4px solid #343a40;
  background-color: #fff; }
  blockquote.bq-alt.bq--primary {
    border-color: #262262; }
  blockquote.bq-alt.bq--secondary {
    border-color: #ed1b5d; }
  blockquote.bq-alt.bq--highlight {
    border-color: #fd7e14; }
  blockquote.bq-alt.bq--accent {
    border-color: #F9BB4D; }
  blockquote.bq-alt.bq--success {
    border-color: #28a745; }
  blockquote.bq-alt.bq--danger {
    border-color: #dc3545; }
  blockquote.bq-alt.bq--warning {
    border-color: #ffc107; }
  blockquote.bq-alt.bq--info {
    border-color: #0182C8; }

hr {
  display: block;
  width: 100%;
  height: 1px;
  margin: 2em auto;
  border: none;
  background-color: #333; }
  hr.hr-inset {
    margin: 2em auto;
    border: none;
    background-color: #bbb;
    box-shadow: 0 1px 0 0 #fff; }
  .content_text hr {
    margin: 1em 0 !important; }

a {
  font-style: normal;
  text-decoration: underline;
  color: #262262; }
  a:hover, a:focus {
    color: #2d2975;
    text-decoration: none; }
  a:focus {
    outline: 1px solid #f691b1; }

a.img-link {
  text-decoration: none; }

.phone {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-decoration: none;
  white-space: nowrap;
  cursor: default;
  color: #ed1b5d; }
  .phone:hover, .phone:active, .phone:focus {
    text-decoration: none;
    color: #ed1b5d;
    outline: none;
    cursor: default; }

.phone.mask {
  color: #343a40; }
  .phone.mask:hover {
    outline: none;
    color: #343a40; }

.list {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em; }
  .list li:not(:last-child) {
    margin-bottom: 0.25em; }

.list-inline, .list_icon, .list-hours, .menu, .list-unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.list-inline li {
  display: inline-block; }

.list_icon li {
  padding-left: 22px;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: left center; }

.list_icon.list-inline li:not(:last-child) {
  margin-right: 15px; }

.icon_check-primary li {
  background-image: url("/_/images/icons/cm_primary.png"); }

.icon_check-secondary li {
  background-image: url("/_/images/icons/cm_secondary.png"); }

.icon_check-success li {
  background-image: url("/_/images/icons/cm_success.png"); }

.list-hours li {
  margin-bottom: 0 !important; }

[class*="list-block"] {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
  [class*="list-block"] li:not(:last-child) {
    margin-bottom: 0.25em; }
  [class*="list-block"] li {
    display: inline-block; }
  [class*="list-block"] li {
    -webkit-transform: skew(-15deg);
    -moz-transform: skew(-15deg);
    -o-transform: skew(-15deg);
    transform: skew(-15deg);
    margin-bottom: 0.5em !important;
    padding: 3px 10px;
    font-weight: 600;
    letter-spacing: 0.025em;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    background-color: #343a40;
    color: #fff; }
  [class*="list-block"] li:not(:last-child) {
    margin-right: 0.25em; }

.list-block--primary li {
  background-color: #2f2c58; }

.list-block--secondary li {
  background-color: #ed1b5d; }

.list-block--highlight li {
  background-color: #fd7e14; }

.list-block--accent li {
  background-color: #F9BB4D; }

.list-block--success li {
  background-color: #28a745; }

.list-block--danger li {
  background-color: #dc3545; }

.list-block--warning li {
  background-color: #ffc107; }

.list-block--info li {
  background-color: #0182C8; }

@media (min-width: 576px) {
  .list-double-sm-up {
    display: flex;
    flex-wrap: wrap; }
    .list-double-sm-up > li {
      width: 50%; } }

@media (min-width: 768px) {
  .list-triple-md-up {
    display: flex;
    flex-wrap: wrap; }
    .list-triple-md-up > li {
      width: 33.33333%; } }

img {
  display: block;
  max-width: 100%;
  height: auto;
  border-style: none; }

.img-main {
  margin: 0 0 15px;
  max-width: 360px; }

.img-coupon {
  max-width: 640px; }

.img-about {
  width: 150px; }

.img-auto {
  width: auto; }

.img-inline {
  display: inline; }

.img-thumbnail {
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff; }

@media (min-width: 768px) {
  .img--right {
    clear: both;
    float: right;
    width: 45%;
    margin: 0 0 15px 20px; } }

@media (min-width: 768px) {
  .img--left {
    clear: both;
    float: left;
    width: 45%;
    margin: 0 20px 15px 0; } }

.img--wide {
  margin: 0 0 15px;
  width: 100%;
  max-width: none; }

.img--auto {
  max-width: auto; }
  @media (min-width: 576px) {
    .img--auto {
      width: auto;
      height: auto; } }

.img-captioned {
  padding: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 15px; }
  .img-captioned img {
    width: 100%;
    padding: 0;
    border: none;
    margin-bottom: 0; }
  .img-captioned span {
    display: block;
    max-width: 360px;
    margin: 10px 5px 2px;
    font-size: 0.92rem;
    font-style: italic;
    line-height: 1.325em;
    color: #4b545c; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  line-height: 1em;
  background-color: #fff;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th,
  .table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3; }

.table-hover tbody tr:hover {
  background-color: #e5e5e5;
  cursor: pointer; }

@media (max-width: 767px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.embed_responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed_responsive.embed_responsive_16by9 {
    padding-bottom: 56.25%; }
  .embed_responsive.embed_responsive_4by3 {
    padding-bottom: 75%; }
  .embed_responsive .embed_responsive_item, .embed_responsive embed, .embed_responsive iframe, .embed_responsive object, .embed_responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.address {
  margin: 0 0 5px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.2em; }

.contact-address {
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  padding: 5px;
  cursor: pointer; }
  .contact-address .address-hover {
    display: flex;
    flex-wrap: wrap;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 800;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #ed1b5d;
    color: #fff;
    opacity: 0; }
  .contact-address:hover .address-hover {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    opacity: 1; }

.alert {
  border: 1px solid #ddd;
  background-color: white;
  color: #343a40;
  padding: 1em;
  border-radius: 3px; }
  .alert .alert-title {
    color: #b7b7b7; }
  .alert .button_close {
    float: right; }

.alert-primary {
  border: 1px solid #262262;
  background-color: #4d45be;
  color: #343a40; }
  .alert-primary .alert-title {
    color: #100e29; }

.alert-secondary {
  border: 1px solid #ed1b5d;
  background-color: #f691b1;
  color: #343a40; }
  .alert-secondary .alert-title {
    color: #ae0e40; }

.alert-highlight {
  border: 1px solid #fd7e14;
  background-color: #fec392;
  color: #343a40; }
  .alert-highlight .alert-title {
    color: #c35a02; }

.alert-accent {
  border: 1px solid #F9BB4D;
  background-color: #fdeac8;
  color: #343a40; }
  .alert-accent .alert-title {
    color: #f19d08; }

.alert-success {
  border: 1px solid #28a745;
  background-color: #71dd8a;
  color: #343a40; }
  .alert-success .alert-title {
    color: #19692c; }

.alert-danger {
  border: 1px solid #dc3545;
  background-color: #efa2a9;
  color: #343a40; }
  .alert-danger .alert-title {
    color: #a71d2a; }

.alert-info {
  border: 1px solid #0182C8;
  background-color: #4abffe;
  color: #343a40; }
  .alert-info .alert-title {
    color: #01517c; }

.alert-warning {
  border: 1px solid #ffc107;
  background-color: #ffe187;
  color: #343a40; }
  .alert-warning .alert-title {
    color: #ba8b00; }

.alert-link {
  border: 1px solid #262262;
  background-color: #4d45be;
  color: #343a40; }
  .alert-link .alert-title {
    color: #100e29; }

.alert-title {
  margin-top: 0;
  margin-right: 34px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  letter-spacing: -0.0325em; }

.block.block_alert {
  padding: 0;
  border-radius: 0;
  border: none; }
  .block.block_alert .container {
    position: relative;
    padding: 15px; }
  .block.block_alert .alert_text .alert-title {
    margin-bottom: 0.5em;
    color: #343a40; }
  .block.block_alert .alert_text p {
    line-height: 1.325em; }
  .block.block_alert .button_close {
    position: absolute;
    top: 0px;
    right: 15px; }

.block.alert_product {
  padding: 15px 0; }
  .block.alert_product .alert_media {
    padding: 30px 30px 15px; }
  .block.alert_product .alert_text {
    padding: 0 30px; }
  @media (max-width: 991px) {
    .block.alert_product .alert_media {
      margin-top: -30px; } }
  @media (min-width: 992px) {
    .block.alert_product .flex_wrap_alert {
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
      .block.alert_product .flex_wrap_alert .alert_media {
        width: 65%;
        padding: 0; }
      .block.alert_product .flex_wrap_alert .alert_text {
        width: 35%;
        padding: 0 30px; } }

.btn {
  display: inline-block;
  padding: 12px 15px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid;
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn small {
    display: block;
    line-height: 1em; }
  .btn:hover {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn:focus {
    outline: 4px solid rgba(0, 0, 0, 0.25); }

.btn-text {
  border-color: #1d2124;
  background-color: #343a40;
  color: #fff; }
  .btn-text:hover, .btn-text:focus {
    background-color: #515a63;
    color: #fff; }

.btn-link {
  border-color: #17153c;
  background-color: #262262;
  color: #fff; }
  .btn-link:hover, .btn-link:focus {
    background-color: #383291;
    color: #fff; }

.btn-primary {
  border-color: #17153c;
  background-color: #262262;
  color: #fff; }
  .btn-primary:hover, .btn-primary:focus {
    background-color: #383291;
    color: #fff; }

.btn-secondary {
  border-color: #c51049;
  background-color: #ed1b5d;
  color: #fff; }
  .btn-secondary:hover, .btn-secondary:focus {
    background-color: #f25687;
    color: #fff; }

.btn-highlight {
  border-color: #dc6502;
  background-color: #fd7e14;
  color: #fff; }
  .btn-highlight:hover, .btn-highlight:focus {
    background-color: #fea153;
    color: #fff; }

.btn-accent {
  border-color: #f7a81c;
  background-color: #F9BB4D;
  color: #fff; }
  .btn-accent:hover, .btn-accent:focus {
    background-color: #fac972;
    color: #fff; }

.btn-success {
  border-color: #1e7e34;
  background-color: #28a745;
  color: #fff; }
  .btn-success:hover, .btn-success:focus {
    background-color: #3ed160;
    color: #fff; }

.btn-danger {
  border-color: #bd2130;
  background-color: #dc3545;
  color: #fff; }
  .btn-danger:hover, .btn-danger:focus {
    background-color: #e56b77;
    color: #fff; }

.btn-info {
  border-color: #016195;
  background-color: #0182C8;
  color: #fff; }
  .btn-info:hover, .btn-info:focus {
    background-color: #0ba8fe;
    color: #fff; }

.btn-review {
  border-color: #b93528;
  background-color: #D54C3F;
  color: #fff; }
  .btn-review:hover, .btn-review:focus {
    background-color: #e07d73;
    color: #fff; }

.btn-warning {
  border-color: #d39e00;
  background-color: #ffc107;
  color: #fff; }
  .btn-warning:hover, .btn-warning:focus {
    background-color: #ffd147;
    color: #fff; }

.btn-block {
  display: block;
  text-align: center; }

.btn-icon {
  vertical-align: bottom; }
  .btn-icon img {
    display: inline-block;
    width: 22px;
    margin-right: 0.75em;
    vertical-align: middle; }
  .btn-icon.btn--lg img {
    width: 28px; }
  .btn-icon.btn--sm img {
    width: 18px; }

.btn--sm {
  padding: 8px 10px;
  font-size: 14px;
  font-size: 0.875rem; }

.btn--md {
  padding: 12px 15px;
  font-size: 16px;
  font-size: 1rem; }

.btn--lg {
  padding: 15px 25px;
  font-size: 18px;
  font-size: 1.125rem; }

.btn--wide {
  display: block;
  width: 100%; }

.button_close {
  padding: 5px;
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 1em;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #bd2130;
  background-color: #dc3545;
  background-image: url("/_/images/icons/close.png");
  background-size: 14px 14px;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;
  cursor: pointer; }
  .button_close:hover {
    border-color: #dc3545;
    background-color: #e4606d; }

[class*="btn-icon"] {
  padding: 15px 15px 15px 45px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1em;
  text-transform: uppercase;
  background-position: 12px 9px;
  background-repeat: no-repeat;
  background-size: 24px; }

.btn-icon--calendar {
  background-image: url("/_/images/icons/btn-calendar.png"); }
  .btn-icon--calendar:hover {
    background-image: url("/_/images/icons/btn-calendar-hover.png"); }

.btn-icon--map {
  background-image: url("/_/images/icons/btn-map.png"); }
  .btn-icon--map:hover {
    background-image: url("/_/images/icons/btn-map-hover.png"); }

.btn-icon--review {
  background-image: url("/_/images/icons/btn-review.png"); }
  .btn-icon--review:hover {
    background-image: url("/_/images/icons/btn-review-hover.png"); }

.button_mobile {
  text-align: left; }

.mobile_toggle {
  width: 48px;
  height: 41px;
  padding: 10px 11px 9px;
  background-color: #ed1b5d;
  border: none;
  border-radius: 4px;
  color: #fff; }
  .mobile_toggle:hover, .mobile_toggle:focus {
    outline: none;
    background-color: #f14a7f;
    cursor: pointer; }

.button-bars {
  display: inline-block;
  height: 100%;
  width: 26px; }

.icon-bar {
  -webkit-transition: transform ease-in-out 300ms;
  -moz-transition: transform ease-in-out 300ms;
  -ms-transition: transform ease-in-out 300ms;
  -o-transition: transform ease-in-out 300ms;
  transition: transform ease-in-out 300ms;
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 4px;
  background-color: #fff; }
  .icon-bar:nth-child(2) {
    margin: 5px 0; }

.canvas-slid .icon-bar:nth-child(2) {
  visibility: hidden; }

.canvas-slid .icon-bar:nth-child(1), .canvas-slid .icon-bar:nth-child(3) {
  -webkit-transition: transform ease-in-out 150ms;
  -moz-transition: transform ease-in-out 150ms;
  -ms-transition: transform ease-in-out 150ms;
  -o-transition: transform ease-in-out 150ms;
  transition: transform ease-in-out 150ms; }

.canvas-slid .icon-bar:nth-child(1) {
  transform: rotate(45deg);
  margin-top: 9px; }

.canvas-slid .icon-bar:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -16px; }

.button_conversion {
  display: inline-block;
  padding: 15px 20px 15px 55px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #bd2130;
  background-color: #dc3545;
  background-image: url("/_/images/icons/truck.png");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 55px auto;
  color: #fff;
  box-shadow: 0 22px 9px -20px rgba(0, 0, 0, 0.7);
  transform: scale(0.925);
  transition: none;
  transition-timing-function: linear;
  transition-duration: 300ms, 300ms, 200ms;
  transition-property: background-position, padding, transform, box-shadow;
  transition-delay: 0ms, 0ms, 0ms, 0ms; }
  .button_conversion:link, .button_conversion:visited, .button_conversion:active {
    text-decoration: none;
    border: 1px solid #bd2130;
    background-color: #dc3545;
    color: #fff; }
  .button_conversion:hover, .button_conversion:focus {
    padding: 15px 55px 15px 20px;
    background-color: #e04b59;
    background-position: right center;
    box-shadow: 0 22px 9px -20px transparent;
    color: #fff;
    transform: scale(1);
    transition-duration: 600ms, 300ms, 100ms, 300ms;
    transition-delay: 200ms, 100ms, 0ms, 300ms; }
  @media (min-width: 992px) {
    .button_conversion {
      font-size: 1.3rem; } }
  @media (min-width: 1200px) {
    .button_conversion {
      font-size: 1.5rem; } }

.call {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1em; }
  .call .phone {
    display: inline-block;
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 1em;
    letter-spacing: -0.04em; }
    .call .phone.company-phone {
      color: #fff; }
    .call .phone.contact-phone {
      color: #ed1b5d; }
  .call.contact-call {
    margin-bottom: 5px; }

.coverage {
  padding: 30px; }
  .coverage .coverage_title {
    margin: 0 0 0.75rem;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #ed1b5d; }
    .coverage .coverage_title a {
      text-decoration: none; }
  .coverage p {
    margin-bottom: 0.5rem;
    line-height: 1.325em; }
    .coverage p:last-of-type {
      margin-bottom: 0; }
  .coverage .coverage_county {
    font-size: 15px;
    font-size: 0.9375rem;
    font-weight: 600; }
  .coverage .coverage_city {
    font-size: 15px;
    font-size: 0.9375rem; }

.block.coverage {
  padding-bottom: 60px;
  text-align: center;
  box-shadow: none;
  border-top: 1px solid #e6e6e6;
  background-color: #fff; }
  .block.coverage .row {
    justify-content: center; }
  @media (min-width: 992px) {
    .block.coverage .wrap {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }
  @media (min-width: 1200px) {
    .block.coverage .wrap {
      flex: 0 0 75%;
      max-width: 75%; } }
  .page_front .block.coverage {
    padding-top: 45px; }

.form {
  font-size: 16px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1em; }

.form_section {
  overflow: hidden;
  margin: 0 0 20px;
  padding: 15px 15px 7px;
  border: 1px solid #ccc; }
  .form_section legend {
    padding: 0 45px 0 10px;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -0.0475em; }

.form-title {
  margin: 0 0 30px; }

.form_group {
  margin: 0 0 8px;
  padding: 0; }
  .form_group.form_hp {
    display: none; }
  .form_group.form_clear {
    clear: both; }

label {
  display: block;
  margin: 0 0 1px 2px;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: -0.0275em; }

.form_control {
  display: block;
  width: 100%;
  margin: 0 0 5px;
  padding: 9px 7px;
  line-height: 1em;
  border-radius: 2px;
  border: 1px solid #ddd;
  background-color: #fff;
  outline: none; }
  .form_control:focus {
    border-color: #ffc107;
    background-color: #fff4d3; }

textarea.form_control {
  min-height: 150px;
  line-height: 1.275em; }

.form_checkbox {
  margin: 0 0 8px;
  vertical-align: top;
  line-height: 1em; }
  .form_checkbox label {
    display: block;
    min-height: 24px;
    padding: 6px 0 7px 32px;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1em;
    cursor: pointer; }
  .form_checkbox .checkbox {
    display: block;
    margin: -3px 5px 0 -29px;
    float: left;
    width: 23px;
    height: 23px; }

.form_error .form_control {
  border-radius: 2px 2px 0 0;
  border-color: #dc3545 !important; }

.error-message {
  margin: -5px 0 0;
  padding: 6px 4px 4px;
  font-size: 0.7em;
  font-weight: 800;
  line-height: 1em;
  text-transform: uppercase;
  background-color: #dc3545;
  color: #fff; }

.form_spam {
  position: relative;
  height: 70px; }
  .form_spam .g-recaptcha {
    position: absolute;
    top: -5px;
    left: -18px;
    -webkit-transform: scale(0.88);
    -moz-transform: scale(0.88);
    -o-transform: scale(0.88);
    transform: scale(0.88); }

.gallery {
  overflow: hidden;
  margin-bottom: 1em; }

.gallery_sidebar img {
  margin-bottom: 0.5em; }

.gallery_lightbox img {
  padding: 4px;
  border: 1px solid #ccc;
  background-color: #fff; }

.gallery_lightbox > a {
  margin-bottom: 1%; }

@media (max-width: 575px) {
  .g2-xs > img, .g2-xs > a, .g2-xs > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-xs > img:nth-child(odd), .g2-xs > a:nth-child(odd), .g2-xs > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-xs > img:nth-child(even), .g2-xs > a:nth-child(even), .g2-xs > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 576px) and (max-width: 767px) {
  .g2-sm > img, .g2-sm > a, .g2-sm > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-sm > img:nth-child(odd), .g2-sm > a:nth-child(odd), .g2-sm > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-sm > img:nth-child(even), .g2-sm > a:nth-child(even), .g2-sm > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g2-md > img, .g2-md > a, .g2-md > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-md > img:nth-child(odd), .g2-md > a:nth-child(odd), .g2-md > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-md > img:nth-child(even), .g2-md > a:nth-child(even), .g2-md > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g2-lg > img, .g2-lg > a, .g2-lg > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-lg > img:nth-child(odd), .g2-lg > a:nth-child(odd), .g2-lg > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-lg > img:nth-child(even), .g2-lg > a:nth-child(even), .g2-lg > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 1200px) {
  .g2-xl > img, .g2-xl > a, .g2-xl > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-xl > img:nth-child(odd), .g2-xl > a:nth-child(odd), .g2-xl > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-xl > img:nth-child(even), .g2-xl > a:nth-child(even), .g2-xl > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 576px) and (max-width: 767px) {
  .g3-sm > img, .g3-sm > a, .g3-sm > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-sm > img:nth-child(3n-2), .g3-sm > a:nth-child(3n-2), .g3-sm > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-sm > img:nth-child(3n), .g3-sm > a:nth-child(3n), .g3-sm > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g3-md > img, .g3-md > a, .g3-md > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-md > img:nth-child(3n-2), .g3-md > a:nth-child(3n-2), .g3-md > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-md > img:nth-child(3n), .g3-md > a:nth-child(3n), .g3-md > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g3-lg > img, .g3-lg > a, .g3-lg > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-lg > img:nth-child(3n-2), .g3-lg > a:nth-child(3n-2), .g3-lg > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-lg > img:nth-child(3n), .g3-lg > a:nth-child(3n), .g3-lg > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 1200px) {
  .g3-xl > img, .g3-xl > a, .g3-xl > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-xl > img:nth-child(3n-2), .g3-xl > a:nth-child(3n-2), .g3-xl > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-xl > img:nth-child(3n), .g3-xl > a:nth-child(3n), .g3-xl > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 576px) and (max-width: 767px) {
  .g4-sm > img, .g4-sm > a, .g4-sm > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-sm > img:nth-child(4n-3), .g4-sm > a:nth-child(4n-3), .g4-sm > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-sm > img:nth-child(4n), .g4-sm > a:nth-child(4n), .g4-sm > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g4-md > img, .g4-md > a, .g4-md > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-md > img:nth-child(4n-3), .g4-md > a:nth-child(4n-3), .g4-md > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-md > img:nth-child(4n), .g4-md > a:nth-child(4n), .g4-md > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g4-lg > img, .g4-lg > a, .g4-lg > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-lg > img:nth-child(4n-3), .g4-lg > a:nth-child(4n-3), .g4-lg > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-lg > img:nth-child(4n), .g4-lg > a:nth-child(4n), .g4-lg > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 1200px) {
  .g4-xl > img, .g4-xl > a, .g4-xl > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-xl > img:nth-child(4n-3), .g4-xl > a:nth-child(4n-3), .g4-xl > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-xl > img:nth-child(4n), .g4-xl > a:nth-child(4n), .g4-xl > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) {
  .g5-lg > img, .g5-lg > a, .g5-lg > .img-captioned {
    display: block;
    float: left;
    width: 19.2%;
    margin-right: 1%; }
    .g5-lg > img:nth-child(5n-4), .g5-lg > a:nth-child(5n-4), .g5-lg > .img-captioned:nth-child(5n-4) {
      clear: both; }
    .g5-lg > img:nth-child(5n), .g5-lg > a:nth-child(5n), .g5-lg > .img-captioned:nth-child(5n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 1200px) {
  .g5-xl > img, .g5-xl > a, .g5-xl > .img-captioned {
    display: block;
    float: left;
    width: 19.2%;
    margin-right: 1%; }
    .g5-xl > img:nth-child(5n-4), .g5-xl > a:nth-child(5n-4), .g5-xl > .img-captioned:nth-child(5n-4) {
      clear: both; }
    .g5-xl > img:nth-child(5n), .g5-xl > a:nth-child(5n), .g5-xl > .img-captioned:nth-child(5n) {
      float: right;
      margin-right: 0; } }

.hours {
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1em; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99000;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 99500;
  text-align: center;
  line-height: 0;
  font-weight: 400; }
  @media (min-width: 768px) {
    .lightbox {
      margin-top: 75px; } }

.lightbox .lb-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  padding: 3px;
  overflow: hidden;
  background-color: white;
  *zoom: 1;
  margin: 0 auto; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url("/_/images/layout/loading.gif") no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("/_/images/icons/lb_prev.png") left 48% no-repeat;
  background-size: 28px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("/_/images/icons/lb_next.png") right 48% no-repeat;
  background-size: 28px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding: 7px 0;
  *zoom: 1;
  width: 100%;
  border-top: 3px solid #fff;
  background-color: #fff;
  background-color: transparent;
  color: #343a40; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  display: inline-block;
  padding: 6px 5px 0;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em; }

.lb-data .lb-number {
  display: none !important;
  visibility: hidden !important; }

.lb-data .lb-close {
  display: block;
  float: right;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  background: url("/_/images/icons/close.png") center center no-repeat;
  background-size: 20px 20px;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.logo {
  display: block;
  margin: -41px auto 10px;
  max-width: 240px;
  line-height: 1em; }

.logo-footer {
  display: block;
  max-width: 200px;
  margin: 0 auto; }

.contact_map {
  margin: 0 0 1em;
  border: 4px solid #fff; }
  @media (min-width: 992px) {
    .contact_map.embed_responsive {
      padding-bottom: 139%; } }
  @media (min-width: 1200px) {
    .contact_map.embed_responsive {
      padding-bottom: 116%; } }

.media_object .media_image img {
  display: block;
  width: 100%; }

@media (min-width: 768px) {
  .media_object {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .media_object .media_image,
    .media_object .media_content {
      position: relative;
      box-sizing: border-box;
      min-height: 1px; }
    .media_object .media_image {
      width: 45%; }
    .media_object .media_content {
      width: 55%; } }

@media (min-width: 992px) {
  .media_object .media_image {
    width: 45%; }
  .media_object .media_content {
    width: 55%; } }

.media_image {
  margin-bottom: 15px; }

.media_content > *:last-child {
  margin-bottom: 0; }

.media-title {
  margin-top: 0; }

.services_wrapper {
  margin-top: 45px; }
  .services_wrapper .service_object {
    padding: 0 0 30px; }
    .services_wrapper .service_object:not(:last-child) {
      margin: 0 0 2em;
      border-bottom: 1px solid #ccc;
      box-shadow: 0 1px 0 0 #fff; }
    .services_wrapper .service_object:nth-child(even) .service_image img {
      box-shadow: 0 0 0 3px #ed1b5d; }
    @media (min-width: 768px) {
      .services_wrapper .service_object {
        align-items: flex-start; }
        .services_wrapper .service_object:nth-child(even) .media_image {
          padding-right: 30px; }
        .services_wrapper .service_object:nth-child(odd) .media_image {
          order: 2;
          padding-left: 30px; }
          .services_wrapper .service_object:nth-child(odd) .media_image img {
            margin: 0 0 0 auto; }
        .services_wrapper .service_object:nth-child(odd) .media_content {
          order: 1; } }
  .services_wrapper .service_image img {
    max-width: 270px;
    padding: 0;
    border-radius: 100%;
    border: 5px solid #fff;
    box-shadow: 0 0 0 3px #262262; }
  .services_wrapper .service_content {
    line-height: 1.4em; }
    .services_wrapper .service_content .service-title {
      font-size: 22px;
      font-size: 1.375rem; }
    .services_wrapper .service_content .list {
      line-height: 1.2em; }

.brand_object {
  align-items: center;
  margin: 0 0 2em;
  padding: 0 0 2em;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 1px 0 0 #fff; }

.menu {
  overflow: hidden;
  margin: 0;
  padding: 0; }

.menu_item {
  box-sizing: border-box;
  list-style-type: none;
  /*		&.open {
			.dropdown_menu {
				display: block;
				height: auto;
			}
			.caret {
				@include transform(rotate(180deg));
			}
		}*/ }
  .menu_item.active .menu_link {
    cursor: default; }
    .menu_item.active .menu_link:hover {
      cursor: default; }

.menu_link {
  -webkit-transition: all ease-in-out 450ms;
  -moz-transition: all ease-in-out 450ms;
  -ms-transition: all ease-in-out 450ms;
  -o-transition: all ease-in-out 450ms;
  transition: all ease-in-out 450ms;
  display: block;
  padding: 12px 15px;
  line-height: 1em;
  text-decoration: none; }
  .menu_link:hover, .menu_link:focus {
    -webkit-transition: all ease-in-out 450ms;
    -moz-transition: all ease-in-out 450ms;
    -ms-transition: all ease-in-out 450ms;
    -o-transition: all ease-in-out 450ms;
    transition: all ease-in-out 450ms;
    outline: none; }

/*			.caret {
				display: inline-block;
				position: relative;
				vertical-align: baseline;
				//border: 1px solid red;
				width: 0.7em;
				height: 0.7em;
				background-image: url("/_/images/icons/caret-down-text.png");
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center center;
			}

				.dropdown-toggle:hover,
				.open .dropdown-toggle {
					.caret {
						background-image: url("/_/images/icons/caret-down-text.png");
					}
				}*/
/*		.dropdown_menu {

			@include transition(all ease-in-out 300ms);
			display: none;
			position: relative;
			z-index: 95000;
			margin: 0;
			padding: 1em;
			min-width: 280px;
			background-color: $white;

			.menu_item {
				list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
				text-align: left;
			}

				.menu_link {
					padding-left: 10px;
					&:hover, &:focus {
						outline: none;					
					}				
				}

			@include media-breakpoint-up(md) {
				position: absolute;
			}

		}*/
.banner_menu {
  padding: 0;
  /*	.dropdown_menu {
		min-width: 0;
		padding: 10px;
		border: 1px solid theme(secondary, dark);
		border-top: none;
		box-shadow: 0 15px 10px -15px rgba(black, 0.6);
		background-color: theme(secondary, base);

		.menu_item:not(:last-child) { border-bottom: 1px solid rgba(black,0.28); }
		.menu_item:not(:first-child) { border-top: 1px solid rgba($white,0.31); }		

		.menu_link {
			padding: 13px 45px 13px 10px;
			background: transparent url("/_/images/layout/hover-primary.svg") 15% center no-repeat;
		}

	}*/ }
  .banner_menu .menu {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: right; }
    .banner_menu .menu > .menu_item {
      display: inline-block;
      padding: 0 2px; }
      .banner_menu .menu > .menu_item.active > .menu_link {
        -webkit-transform: none;
        -moz-transform: none;
        -o-transform: none;
        transform: none;
        background: #f9f9f9;
        font-weight: 600;
        text-shadow: none;
        color: #ed1b5d; }
      .banner_menu .menu > .menu_item.open > .menu_link {
        border-radius: 3px 3px 0 0;
        background-color: #ed1b5d; }
      .banner_menu .menu > .menu_item:last-child > .menu_link {
        -webkit-transform: none;
        -moz-transform: none;
        -o-transform: none;
        transform: none;
        font-weight: 800;
        background: #28a745 url("/_/images/layout/hover-conversion.svg") 15% center no-repeat;
        background-size: 0px;
        color: #fff; }
        .banner_menu .menu > .menu_item:last-child > .menu_link:hover {
          background-size: 200%; }
      .banner_menu .menu > .menu_item:last-child.active > .menu_link {
        background-image: none; }
      .banner_menu .menu > .menu_item > .menu_link {
        -webkit-transform: scale(0.92);
        -moz-transform: scale(0.92);
        -o-transform: scale(0.92);
        transform: scale(0.92);
        border-radius: 3px;
        background: #262262 url("/_/images/layout/hover-secondary.svg") 15% center no-repeat; }
        .banner_menu .menu > .menu_item > .menu_link:hover {
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -o-transform: scale(1);
          transform: scale(1); }
  .banner_menu .menu_link {
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    display: block;
    padding: 10px 15px 11px;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    background-size: 0px !important;
    color: #fff; }
    .banner_menu .menu_link:hover {
      -webkit-transition: all ease-in-out 500ms;
      -moz-transition: all ease-in-out 500ms;
      -ms-transition: all ease-in-out 500ms;
      -o-transition: all ease-in-out 500ms;
      transition: all ease-in-out 500ms;
      background-size: 200% !important;
      background-position: center center !important; }
    .banner_menu .menu_link .caret {
      background-image: url("/_/images/icons/caret.png"); }

.nav_mobile {
  display: none;
  position: fixed;
  z-index: 999999;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 90%;
  max-width: 320px;
  height: 100%;
  padding: 30px 15px;
  border-right: 3px solid #fff;
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #262262;
  color: #fff; }
  .nav_mobile.in {
    display: block; }
  @media (min-width: 768px) {
    .nav_mobile {
      display: none !important; } }
  .nav_mobile h4 {
    font-size: 1.2em;
    font-weight: 700;
    text-transform: uppercase; }
  .nav_mobile hr {
    margin: 30px 0;
    background-color: #fff; }
  .nav_mobile .nav_menu > .menu_item:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.35); }
  .nav_mobile .nav_menu > .menu_item:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.5); }
  .nav_mobile .nav_menu > .menu_item.active .menu_link {
    background-color: #fff;
    color: #262262; }
    .nav_mobile .nav_menu > .menu_item.active .menu_link:hover, .nav_mobile .nav_menu > .menu_item.active .menu_link:focus {
      outline: none;
      background-color: #fff;
      color: #262262; }
  .nav_mobile .nav_menu > .menu_item.open > .menu_link {
    background-color: rgba(255, 255, 255, 0.2); }
  .nav_mobile .nav_menu > .menu_item.open .dropdown_menu {
    background-color: rgba(255, 255, 255, 0.2); }
  .nav_mobile .nav_menu > .menu_item > .menu_link {
    padding: 12px 10px;
    font-weight: 600;
    background-color: transparent;
    color: #fff; }
    .nav_mobile .nav_menu > .menu_item > .menu_link:hover, .nav_mobile .nav_menu > .menu_item > .menu_link:focus {
      outline: none;
      background-color: #ed1b5d;
      color: #fff; }
  .nav_mobile .dropdown_menu {
    padding: 0; }
    .nav_mobile .dropdown_menu .menu_item {
      font-size: 0.925em; }
    .nav_mobile .dropdown_menu .menu_link {
      background-color: transparent;
      color: #fff; }
      .nav_mobile .dropdown_menu .menu_link:hover, .nav_mobile .dropdown_menu .menu_link:focus {
        outline: none;
        background-color: rgba(0, 0, 0, 0.2);
        color: #fff; }
  .nav_mobile .mobile-toggle {
    font-size: 1.2em;
    color: #fff;
    text-decoration: none; }
    .nav_mobile .mobile-toggle img {
      display: inline-block;
      width: 0.8em;
      margin-right: 10px; }

.menu_sub .menu-title {
  font-size: 1.325rem;
  letter-spacing: -0.0625em;
  text-transform: uppercase; }

.menu_sub .menu_item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2); }

.menu_sub .menu_item.active .menu_link {
  padding: 12px 9px 12px 19px;
  font-weight: 600;
  background: url("/_/images/icons/caret-right-secondary.png"), #fff;
  background-repeat: no-repeat, repeat;
  background-position: -4px center, left center;
  background-size: 16px, 201% 100%;
  color: #ed1b5d; }

.menu_sub .menu_link {
  padding: 12px 9px 12px 8px;
  font-size: 15px;
  line-height: 1em;
  color: #343a40;
  background: url("/_/images/icons/caret-right-white.png"), linear-gradient(to right, #ed1b5d 50%, transparent 50%);
  background-position: -10px center, right center;
  background-repeat: no-repeat, repeat;
  background-size: 1px, 201% 100%; }
  .menu_sub .menu_link:hover {
    padding: 12px 9px 12px 19px;
    background-position: -4px center, left center;
    background-size: 16px, 200% 100%;
    color: #fff; }

body.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99000;
  cursor: pointer; }
  .modal.show {
    display: block; }

.modal-dialog {
  position: relative;
  z-index: 99500;
  width: 90%;
  max-width: 640px;
  margin: 20px auto;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: default; }
  .modal-dialog p {
    margin-bottom: 0;
    padding: 10px 5px;
    font-size: 14px;
    line-height: 1.25em; }
    .modal-dialog p:nth-child(even) {
      background-color: #ddd; }

.modal-header {
  margin: 0 0 15px;
  padding: 0 0 10px;
  border-bottom: 1px solid #ccc; }

.modal-title {
  margin: 15px 0 0;
  font-size: 18px; }

.review {
  padding: 0 60px; }
  .review cite {
    display: block;
    margin-top: 1em;
    font-size: 15px;
    font-size: 0.9375rem;
    font-style: normal;
    line-height: 1em;
    text-align: center; }
    .review cite img {
      display: block;
      width: 120px;
      margin: 0 auto 5px; }
  .review .reivew-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }
  .widget_review .review {
    padding: 0; }
  .reviews_main_wrapper .review {
    margin-bottom: 20px;
    padding: 30px;
    text-align: left;
    border: 1px solid #bbb;
    border-left: 10px solid #fd7e14;
    background-color: rgba(255, 255, 255, 0.5); }
    .reviews_main_wrapper .review p {
      margin: 0; }
    .reviews_main_wrapper .review cite {
      text-align: left;
      line-height: 1.325em; }
      .reviews_main_wrapper .review cite img {
        margin: 0 0 5px; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

.slick-slide img {
  display: block;
  margin: 0; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/_/images/layout/ajax-loader.gif") center center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  z-index: 95000;
  position: absolute;
  display: block;
  height: 100%;
  top: 0;
  bottom: 0;
  width: 55px;
  font-size: 0px;
  line-height: 0px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  color: transparent;
  padding: 0;
  border: none;
  outline: none;
  opacity: 0; }
  .slick-prev:hover,
  .slick-next:hover {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    outline: none;
    opacity: 0.6; }

.slick-prev {
  left: 0;
  background-image: url("/_/images/icons/angle-left.svg"); }

.slick-next {
  right: 0;
  background-image: url("/_/images/icons/angle-right.svg"); }

.slideshow:hover .slick-prev,
.slideshow:hover .slick-next {
  opacity: 0.6; }

.slideshow .slick-prev,
.slideshow .slick-next {
  z-index: 999999;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 40px;
  background-color: transparent;
  background-position: center center;
  background-size: 14px;
  opacity: 0.3; }
  .slideshow .slick-prev:hover,
  .slideshow .slick-next:hover {
    background-size: 18px;
    opacity: 1; }

.slideshow .slide_caption {
  line-height: 1em;
  padding: 20px 15px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background-color: #1d2124;
  color: #fff; }

.slideshow .caption-title {
  margin: 0 0 0.5em;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1em; }

.slideshow .caption-text {
  margin: 0;
  font-size: 0.95rem;
  line-height: 1.2em; }

.slideshow .caption-text + .caption-text {
  margin-top: 0.5em; }

.slider_jumbo {
  max-width: 1220px;
  margin: 0 auto; }
  .slider_jumbo .slide {
    padding: 0 50px; }
  .slider_jumbo .slick-prev {
    background-image: url("/_/images/icons/slide_prev-white.png"); }
  .slider_jumbo .slick-next {
    background-image: url("/_/images/icons/slide_next-white.png"); }
  .slider_jumbo .slide_wrap {
    border: 3px solid #fff; }
  .slider_jumbo .slide_media {
    background-position: center center;
    background-size: cover; }
  .slider_jumbo .slide-placeholder {
    width: 100%; }
  .slider_jumbo .slide_caption {
    padding: 10px 10px 13px;
    text-align: center;
    background-color: #c51049; }
    .slider_jumbo .slide_caption .btn {
      margin-top: 5px; }
    .slider_jumbo .slide_caption .caption-title {
      margin: 0;
      font-size: 15px;
      font-size: 0.9375rem;
      line-height: 1.375em;
      letter-spacing: -0.0375em; }
  @media (min-width: 576px) {
    .slider_jumbo .slide_caption .caption-title {
      font-size: 18px;
      font-size: 1.125rem; } }
  @media (min-width: 768px) {
    .slider_jumbo .slide_01 .slide_media {
      background-image: url("/_/images/slides/jumbo/lg/01.jpg"); }
    .slider_jumbo .slide_02 .slide_media {
      background-image: url("/_/images/slides/jumbo/lg/02.jpg"); }
    .slider_jumbo .slide_03 .slide_media {
      background-image: url("/_/images/slides/jumbo/lg/03.jpg"); }
    .slider_jumbo .slide_04 .slide_media {
      background-image: url("/_/images/slides/jumbo/lg/04.jpg"); }
    .slider_jumbo .slide_05 .slide_media {
      background-image: url("/_/images/slides/jumbo/lg/05.jpg"); }
    .slider_jumbo .slide_06 .slide_media {
      background-image: url("/_/images/slides/jumbo/lg/06.jpg"); }
    .slider_jumbo .slide_07 .slide_media {
      background-image: url("/_/images/slides/jumbo/lg/07.jpg"); }
    .slider_jumbo .slide_08 .slide_media {
      background-image: url("/_/images/slides/jumbo/lg/08.jpg"); }
    .slider_jumbo .slide_09 .slide_media {
      background-image: url("/_/images/slides/jumbo/lg/09.jpg"); }
    .slider_jumbo .slide_10 .slide_media {
      background-image: url("/_/images/slides/jumbo/lg/10.jpg"); }
    .slider_jumbo .slide_11 .slide_media {
      background-image: url("/_/images/slides/jumbo/lg/11.jpg"); }
    .slider_jumbo .slide_12 .slide_media {
      background-image: url("/_/images/slides/jumbo/lg/12.jpg"); }
    .slider_jumbo .slide-placeholder {
      visibility: hidden; }
    .slider_jumbo .slide_caption {
      padding: 12px 20px;
      text-align: left; }
      .slider_jumbo .slide_caption .caption-title {
        font-size: 20px;
        font-size: 1.25rem; }
      .slider_jumbo .slide_caption .btn {
        float: right;
        margin: 0; } }
  @media (min-width: 992px) {
    .slider_jumbo .slide_caption {
      margin-top: -58px;
      background-color: rgba(0, 0, 0, 0.7); }
      .slider_jumbo .slide_caption .caption-title {
        font-size: 24px;
        font-size: 1.5rem; }
      .slider_jumbo .slide_caption .btn {
        font-size: 18px;
        font-size: 1.125rem; } }

.slider_reviews .slick-prev,
.slider_reviews .slick-next {
  background-color: #f9f9f9; }

.slider_reviews .slick-prev {
  background-image: url("/_/images/icons/slide_prev-review.png"); }

.slider_reviews .slick-next {
  background-image: url("/_/images/icons/slide_next-review.png"); }

@media (min-width: 992px) {
  .slider_reviews {
    /*		.slick-prev,
		.slick-next {
			width: 44px;
			background-size: 100%;
		}
*/ } }

.widget {
  margin: 0 0 1.5em;
  padding: 20px 15px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 25px 20px -25px rgba(0, 0, 0, 0.3);
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: none;
  border: 3px solid #343a40; }
  .widget.widget--primary {
    border-color: #262262; }
  .widget.widget--secondary {
    border-color: #ed1b5d; }
  .widget.widget--highlight {
    border-color: #fd7e14; }
  .widget.widget--accent {
    border-color: #F9BB4D; }
  .widget.widget--success {
    border-color: #28a745; }
  .widget.widget--danger {
    border-color: #dc3545; }
  .widget.widget--warning {
    border-color: #ffc107; }
  .widget.widget--info {
    border-color: #0182C8; }
  .widget.widget--text {
    border-color: #343a40; }
  .widget.widget--review {
    border-color: #D54C3F; }

.widget-title {
  margin: 0 0 0.5em;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: -0.0425em;
  line-height: 1em;
  color: #343a40; }
  .widget--primary .widget-title {
    color: #262262; }
  .widget--secondary .widget-title {
    color: #ed1b5d; }
  .widget--highlight .widget-title {
    color: #fd7e14; }
  .widget--accent .widget-title {
    color: #F9BB4D; }
  .widget--success .widget-title {
    color: #28a745; }
  .widget--danger .widget-title {
    color: #dc3545; }
  .widget--warning .widget-title {
    color: #ffc107; }
  .widget--info .widget-title {
    color: #0182C8; }
  .widget--text .widget-title {
    color: #343a40; }
  .widget--review .widget-title {
    color: #D54C3F; }

.widget-text > *:last-child {
  margin-bottom: 0; }

.widget-text p {
  line-height: 1.375em; }

.widget_product img {
  margin-bottom: 10px; }

.widget_list ul > li {
  margin: 0;
  padding: 5px 8px; }
  .widget_list ul > li:not(:last-child) {
    border-bottom: 1px dotted #ccc; }
  .widget_list ul > li:nth-child(even) {
    background-color: #f8f8f8; }
  .widget_list ul > li p {
    margin: 0 0 5px;
    font-size: 0.9rem;
    font-style: italic;
    line-height: 1.1em; }

/*
.widget-subtitle {
	margin: 15px -24px;
	padding: 0.5em 24px;
	font-size: 1.1rem;
	font-weight: $heading-weight-bold;
	background-color: #e5e5e5;
}

.widget-note {
	font-size: 0.925rem;
	font-style: italic;
	line-height: 1.25em;

}

	.foot-note {
		font-weight: $body-weight-bolder;
		color: theme(danger, base);
	}*/
.widget_menu {
  border-width: 2px; }
  .widget_menu .widget-title a {
    color: #262262;
    text-decoration: none; }
    .widget_menu .widget-title a:hover {
      color: #ed1b5d; }
  .widget_menu .menu_item:not(:last-child) {
    border-bottom: 1px dotted #aaa; }
  .widget_menu .menu_item.active .menu_link {
    background-color: #ed1b5d;
    color: #fff; }
  .widget_menu .menu_link {
    color: #262262; }
    .widget_menu .menu_link:hover {
      color: #fff;
      background-color: #262262; }

.widget_review {
  border: 3px solid #D54C3F;
  box-shadow: none; }
  .widget_review .review cite {
    vertical-align: middle; }
    .widget_review .review cite img {
      display: block;
      margin: 0 auto 10px;
      vertical-align: middle; }
  @media (min-width: 768px) and (max-width: 991px) {
    .widget_review .review cite img {
      display: inline-block;
      margin: 0 7px 0 0; } }

.block {
  padding: 30px 15px; }

.block-title {
  margin-top: 0; }

.block_more {
  text-align: center; }

.block_reviews {
  padding: 45px 0 0; }
  .block_reviews hr {
    margin: 0;
    background-color: #ccc;
    box-shadow: 0 1px 0 #fff; }
    .block_reviews hr:first-of-type {
      margin-bottom: 30px; }
    .block_reviews hr:last-of-type {
      margin-top: 30px; }
  .block_reviews .reviews_title {
    margin-bottom: 15px; }
  .block_reviews .reviews_content {
    text-align: center; }
  .block_reviews .review-wrap p {
    margin-bottom: 0; }
  .block_reviews .reviews_feedback {
    padding: 10px 0 30px; }
  @media (min-width: 992px) {
    .block_reviews .row {
      justify-content: center; }
    .block_reviews .reviews_content {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; } }
  @media (min-width: 1200px) {
    .block_reviews .reviews_content {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }

.announce {
  padding: 15px;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 800;
  line-height: 1em;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
  border-top: 3px solid #f9f9f9;
  border-bottom: 3px solid #f9f9f9;
  background-image: linear-gradient(to right, #d98d07, #f7a81c 40%, #f7a81c 60%, #d98d07);
  color: #fff; }
  @media (min-width: 768px) {
    .announce {
      font-size: 17px;
      font-size: 1.0625rem; } }

.block_associations {
  text-align: center; }
  .block_associations img {
    display: inline-block; }

.block_jumbo {
  padding-left: 15px;
  padding-right: 15px;
  background-color: #352f88;
  background: linear-gradient(-45deg, transparent 15%, rgba(255, 255, 255, 0.2) 50%, transparent 85%), linear-gradient(-150deg, rgba(0, 0, 0, 0.4) 20%, transparent 40%, transparent 60%, rgba(0, 0, 0, 0.4) 80%), #352f88; }

/*	.jumbo_conversion {
		text-align: center;
		color: $white;
		@include media-breakpoint-up(sm) {
			@include make-flex;
			align-items: center;
			justify-content: center;
			margin-top: 30px;
			h2 { margin: 0 15px 0 0; }
		}
	}*/
.block_welcome {
  padding: 25px 0;
  text-align: center;
  border-top: 3px solid #f9f9f9;
  background-color: #ed1b5d;
  background-image: linear-gradient(to right, #ae0e40, #ed1b5d 40%, #ed1b5d 60%, #ae0e40); }

.welcome_title .lead {
  font-weight: 800;
  line-height: 1.2em;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
  color: #fff; }

.service_icon .svgColor, .service_icon .svgBgFix {
  -webkit-transition: fill ease-in-out 200ms;
  -moz-transition: fill ease-in-out 200ms;
  -ms-transition: fill ease-in-out 200ms;
  -o-transition: fill ease-in-out 200ms;
  transition: fill ease-in-out 200ms; }

.block_service {
  margin-top: 20px;
  padding-bottom: 0;
  background-color: #262262;
  color: #fff; }
  .block_service .service_title {
    margin-bottom: 15px; }

/* ===== INDIVIDUAL SERVICES ===== */
.block_service_sub .row {
  justify-content: center;
  align-items: stretch; }

@media (min-width: 768px) {
  .block_service_sub .service_sub {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

.service_sub {
  padding: 5px; }

.service-sub-title {
  margin: 0 0 10px;
  font-size: 18px;
  font-size: 1.125rem; }

.service_wrapper {
  display: block;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  border: 2px solid #ed1b5d;
  background: url("/_/images/layout/hover-primary.svg") center center no-repeat;
  background-size: 0;
  color: #343a40;
  -webkit-transition: all ease-in-out 500ms;
  -moz-transition: all ease-in-out 500ms;
  -ms-transition: all ease-in-out 500ms;
  -o-transition: all ease-in-out 500ms;
  transition: all ease-in-out 500ms; }
  .service_wrapper p {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.475em; }
  .service_wrapper span {
    display: inline-block;
    padding: 2px 10px;
    font-weight: 600;
    border-radius: 3px;
    background-color: #ed1b5d;
    color: #fff; }
  .service_wrapper .svgColor {
    fill: #262262; }
  .service_wrapper .svgBgFix {
    fill: #fff; }
  .service_wrapper .svgColor,
  .service_wrapper .svgBgFix {
    -webkit-transition: fill ease-in-out 500ms;
    -moz-transition: fill ease-in-out 500ms;
    -ms-transition: fill ease-in-out 500ms;
    -o-transition: fill ease-in-out 500ms;
    transition: fill ease-in-out 500ms; }
  .service_wrapper:hover {
    color: #fff;
    border-color: #17153c;
    text-shadow: 0 1px 2px #888;
    background-size: 300%;
    -webkit-transition: all ease-in-out 500ms;
    -moz-transition: all ease-in-out 500ms;
    -ms-transition: all ease-in-out 500ms;
    -o-transition: all ease-in-out 500ms;
    transition: all ease-in-out 500ms; }
    .service_wrapper:hover .svgColor,
    .service_wrapper:hover .svgBgFix {
      -webkit-transition: fill ease-in-out 500ms;
      -moz-transition: fill ease-in-out 500ms;
      -ms-transition: fill ease-in-out 500ms;
      -o-transition: fill ease-in-out 500ms;
      transition: fill ease-in-out 500ms; }
    .service_wrapper:hover .svgColor {
      fill: #fff; }
    .service_wrapper:hover .svgBgFix {
      fill: #262262; }

.page_title {
  padding: 0;
  text-shadow: 0 1px 2px #888;
  border-bottom: 5px solid #fff;
  background: linear-gradient(-45deg, transparent 15%, rgba(255, 255, 255, 0.3) 50%, transparent 85%), linear-gradient(-150deg, rgba(0, 0, 0, 0.2) 20%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.2) 80%), #262262;
  color: #fff; }
  .page_title .image_wrap {
    padding: 15px 15px; }
  .page_title .page-title {
    margin: 0;
    font-weight: 900;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4); }
  .page_title .title_wrap {
    padding: 0.75em 0; }
  @media (min-width: 768px) {
    .page_title .image_wrap {
      padding-top: 45px; } }
  @media (min-width: 992px) {
    .page_title .image_wrap {
      padding-top: 90px; }
    .page_title .wrap {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }

@media (min-width: 768px) {
  .pt_img .image_wrap {
    max-width: 1200px;
    margin: 0 auto;
    background-image: contain;
    background-position: center center;
    background-image: url("/_/images/page-titles/service.jpg"); }
  .pt_img .title_wrap {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6) 40%, transparent); }
  .pt_img.service_design .image_wrap {
    background-image: url("/_/images/page-titles/design.jpg"); }
  .pt_img.service_install .image_wrap {
    background-image: url("/_/images/page-titles/installation.jpg"); }
  .pt_img.service_maintain .image_wrap {
    background-image: url("/_/images/page-titles/maintenance.jpg"); } }

.banner {
  padding: 15px 0 25px;
  text-align: center; }
  @media (min-width: 768px) and (max-width: 991px) {
    .banner .logo {
      max-width: 200px; } }
  @media (min-width: 768px) {
    .banner {
      padding-top: 30px;
      border-bottom: none; }
      .banner .logo {
        max-width: 200px;
        margin: 0; } }

@media (min-width: 768px) {
  .banner_contact {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-end;
    text-align: right; }
    .banner_contact .call, .banner_contact .address {
      margin: 0;
      padding: 5px 10px; }
    .banner_contact .address {
      order: 1;
      border-right: 1px solid #ccc; }
    .banner_contact .call {
      order: 2; } }

.sidebar .form_quick {
  margin-bottom: 20px; }

@media (max-width: 991px) {
  .sidebar {
    padding-top: 45px; } }

.site_info {
  position: relative;
  padding: 30px 0;
  text-align: center;
  border-top: 5px solid #ed1b5d;
  background-color: #17153c;
  color: #fff; }
  .site_info::before, .site_info::after {
    bottom: 100%;
    left: 50%;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent; }
  .site_info::after {
    border: solid transparent;
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
    border-bottom: 35px solid transparent;
    border-bottom-color: #17153c;
    margin-left: -80px; }
  .site_info::before {
    border: solid transparent;
    border-left: 93px solid transparent;
    border-right: 93px solid transparent;
    border-bottom: 42px solid transparent;
    border-bottom-color: #ed1b5d;
    margin-left: -93px; }

.info_copyright {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3em;
  text-align: center; }
  .info_copyright a {
    font-weight: 600;
    line-height: 1em;
    color: #fff; }
  .info_copyright .copyright-list {
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.hidden {
  display: none !important; }

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden--lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden--xl {
    display: none !important; } }

[class*="visible-"] {
  display: none; }

@media (max-width: 575px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible--lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible--xl {
    display: block !important; } }

.db {
  display: block !important; }

.mt0 {
  margin-top: 0; }

.mt15 {
  margin-top: 15px; }

.mb0 {
  margin-bottom: 0; }

.mb5 {
  margin-bottom: 5px; }

.mb15 {
  margin-bottom: 15px; }

.pt0 {
  padding-top: 0; }

.pt15 {
  padding-top: 15px; }

.pb0 {
  padding-bottom: 0; }

.pb15 {
  padding-bottom: 15px; }

.no-transform {
  transform: none !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.ff--serif {
  font-family: "Roboto Slab", "Times New Roman", Georgia, Times, serif !important; }

.ff--sans {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

.txt--lg {
  font-size: 115% !important; }

.txt--md {
  font-size: inherit !important; }

.txt--sm {
  font-size: 85% !important; }

.bw0 {
  font-weight: 400 !important; }

.bw1 {
  font-weight: 600 !important; }

.bw2 {
  font-weight: 800 !important; }

.hw0 {
  font-weight: 400 !important; }

.hw1 {
  font-weight: 700 !important; }

.hw2 {
  font-weight: 700 !important; }

.ls--sm {
  letter-spacing: -0.0375em; }

.ls--rg {
  letter-spacing: 0; }

.ls--lg {
  letter-spacing: 0.275em; }

.lh1 {
  line-height: 1.1em !important; }

.lh125 {
  line-height: 1.25em !important; }

.txt--left {
  text-align: left !important; }

.txt--right {
  text-align: right !important; }

.txt--center {
  text-align: center !important; }

.txt--up {
  text-transform: uppercase !important; }

.txt--cap {
  text-transform: capitalize !important; }

.color--text {
  color: #343a40 !important; }

.color--white {
  color: #fff !important; }

.color--primary {
  color: #262262 !important; }

.color--secondary {
  color: #ed1b5d !important; }

.color--highlight {
  color: #fd7e14 !important; }

.color--link {
  color: #262262 !important; }

.color--review {
  color: #D54C3F !important; }

.color--success {
  color: #28a745 !important; }

.color--danger {
  color: #dc3545 !important; }

.color--warning {
  color: #ffc107 !important; }

.color--info {
  color: #0182C8 !important; }

.bg--text {
  background-color: #343a40 !important; }

.bg--white {
  background-color: #fff !important; }

.bg--primary {
  background-color: #262262 !important; }

.bg--secondary {
  background-color: #ed1b5d !important; }

.bg--highlight {
  background-color: #fd7e14 !important; }

.bg--link {
  background-color: #262262 !important; }

.bg--review {
  background-color: #D54C3F !important; }

.bg--success {
  background-color: #28a745 !important; }

.bg--danger {
  background-color: #dc3545 !important; }

.bg--warning {
  background-color: #ffc107 !important; }

.bg--info {
  background-color: #0182C8 !important; }

.bg--transparent {
  background-color: transparent !important; }

.bg--body {
  background-color: #f9f9f9 !important; }

.gdfancybg--text {
  background: #343a40;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #343a40; }

.gdfancybg--white {
  background: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #fff; }

.gdfancybg--primary {
  background: #262262;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #262262; }

.gdfancybg--secondary {
  background: #ed1b5d;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #ed1b5d; }

.gdfancybg--highlight {
  background: #fd7e14;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #fd7e14; }

.gdfancybg--review {
  background: #D54C3F;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #D54C3F; }

.gdfancybg--success {
  background: #28a745;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #28a745; }

.gdfancybg--danger {
  background: #dc3545;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #dc3545; }

.gdfancybg--warning {
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #ffc107; }

.gdfancybg--info {
  background: #0182C8;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #0182C8; }
