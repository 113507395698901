.service_icon {
	.svgColor, .svgBgFix { @include transition(fill ease-in-out 200ms); }
}

.block_service {
	margin-top: 20px;
	padding-bottom: 0;
	background-color: theme(primary, base);
	color: $white;

	.service_title {
		margin-bottom: 15px;
	}

}


/* ===== INDIVIDUAL SERVICES ===== */
.block_service_sub {
	
	.row { justify-content: center; align-items: stretch; }

	@include media-breakpoint-up(md) {
		.service_sub { @include make-col(4); }
	}
}


.service_sub {
	padding: 5px;
}

	.service-sub-title {
		margin: 0 0 10px;
		@include font-size(18);
	}

	.service_wrapper {

		display: block;
		padding: 20px; 
		text-align: center;
		text-decoration: none;
		border: 2px solid theme(secondary, base);
		background: url("/_/images/layout/hover-primary.svg") center center no-repeat;
		background-size: 0;
		color: $color-text;
		@include transition(all ease-in-out 500ms);

		p {
			@include font-size(15);
			line-height: 1.475em;
		}

		span {
			display: inline-block;
			padding: 2px 10px;
			font-weight: $body-weight-bold;
			border-radius: 3px;
			background-color: theme(secondary, base);
			color: $white;
		}

		.svgColor { fill: theme(primary, base); }
		.svgBgFix { fill: $white; }

		.svgColor,
		.svgBgFix { @include transition(fill ease-in-out 500ms); }

		&:hover {
			color: $white;
			border-color: theme(primary, dark);
			text-shadow: 0 1px 2px #888;
			background-size: 300%;
			@include transition(all ease-in-out 500ms); 
			.svgColor,
			.svgBgFix { @include transition(fill ease-in-out 500ms); }
			.svgColor { fill: $white; }
			.svgBgFix { fill: theme(primary, base); }
		}

	}

