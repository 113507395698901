.block {
	padding: 30px 15px;
}

.block_title {}

	.block-title {
		margin-top: 0;
	}

.block_content {}

.block_more {
	text-align: center;
}

.block_reviews {

	padding: 45px 0 0;

	hr {
		margin: 0;
		background-color: #ccc;
		box-shadow: 0 1px 0 $white;
		&:first-of-type { margin-bottom: 30px; }
		&:last-of-type { margin-top: 30px; }
	}

	.reviews_title {
		margin-bottom: 15px;
	}

	.reviews_content {
		text-align: center;
		//padding: 0 45px;
	}

		.review-wrap p { margin-bottom: 0; }

	.reviews_feedback {
		padding: 10px 0 30px;
	}

	@include media-breakpoint-up(lg) {
		.row { justify-content: center; }
		.reviews_content { @include make-col(11); }
	}

	@include media-breakpoint-up(xl) {
		.reviews_content { @include make-col(10); }
	}

}