.banner {
	
	padding: 15px 0 25px;
	text-align: center;

	@include media-breakpoint-only(md) {
		.logo { max-width: 200px; }
	}

	@include media-breakpoint-up(md) {

		padding-top: 30px;
		border-bottom: none;
		.logo {
			max-width: 200px;
			margin: 0;
		}

	}

}

		.banner_contact {

			@include media-breakpoint-up(md) {
				@include make-flex();
				align-items: flex-start;
				justify-content: flex-end;
				text-align: right;
				.call, .address {
					margin: 0;
					padding: 5px 10px;
				}
				.address {
					order: 1;
					border-right: 1px solid #ccc;
				}
				.call { order: 2; }
			}

		}
