.address {
	margin: 0 0 5px;
	@include font-size(14);
	line-height: 1.2em;
}

	.contact-address {
		display: inline-block;
		position: relative;
		margin-bottom: 0;
		padding: 5px;
		cursor: pointer;
		.address-hover {
			@include make-flex();
			@include transform(scale(0));
			@include transition(all ease-in-out 300ms);
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			@include font-size(14);
			font-weight: $body-weight-bolder;
			text-decoration: none;
			text-transform: uppercase;
			background-color: theme(secondary, base);
			color: $white;
			opacity: 0;
		}
		&:hover .address-hover {
			@include transform(scale(1));
			@include transition(all ease-in-out 300ms);
			opacity: 1;
		}
	}