.contact_map {
	margin: 0 0 1em;
	border: 4px solid $white;

	&.embed_responsive {
		@include media-breakpoint-up(lg) {
			padding-bottom: 139%;
		}	
		@include media-breakpoint-up(xl) {
			padding-bottom: 116%;
		}		
	}

}