.block_welcome {
	padding: 25px 0;
	text-align: center;
	border-top: 3px solid $body-bg;
	background-color: theme(secondary, base);
	background-image: linear-gradient(
		to right,
		darken(theme(secondary, dark),5%),
		theme(secondary, base) 40%,
		theme(secondary, base) 60%,
		darken(theme(secondary, dark),5%)
	);

}

	.welcome_title .lead {
		font-weight: $body-weight-bolder;
		line-height: 1.2em;
		text-shadow: 0 1px 1px rgba(black, 0.4);
		color: $white;
	}

