.site_info {
	position: relative;
	padding: 30px 0;
	text-align: center;
	border-top: 5px solid theme(secondary, base);
	background-color: theme(primary, dark);
	color: $white;

	&::before,
	&::after {
		bottom: 100%;
		left: 50%;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: transparent;			
	}

	&::after {
		border: solid transparent;
		border-left: 80px solid transparent;
		border-right: 80px solid transparent;
		border-bottom: 35px solid transparent;
		border-bottom-color: theme(primary, dark);
		margin-left: -80px;
	}

	&::before {
		border: solid transparent;
		border-left: 93px solid transparent;
		border-right: 93px solid transparent;
		border-bottom: 42px solid transparent;
		border-bottom-color: theme(secondary, base);
		margin-left: -93px;
	}

}


.info_copyright {

	@include font-size(14);
	line-height: 1.3em;
	text-align: center; 

	a {
		font-weight: $body-weight-bold;
		line-height: 1em;
		color: $white;
	}

	.copyright-list { @include list_unstyled; }

}