.banner_menu {
	padding: 0;

	.menu {

		display: block;
		width: 100%;
		margin: 0;
		padding: 0;
		text-align: right;

		> .menu_item {

			display: inline-block;
			padding: 0 2px;

			&.active {
				> .menu_link {
					@include transform(none);
					background: $body-bg;
					font-weight: $body-weight-bold;
					text-shadow: none;
					color: theme(secondary, base);
				}
			}

			&.open {
				> .menu_link {
					border-radius: 3px 3px 0 0;
					background-color: theme(secondary, base);
				}
			}

			&:last-child {
				> .menu_link {
					@include transform(none);
					font-weight: $body-weight-bolder;
					background: theme(success, base) url("/_/images/layout/hover-conversion.svg") 15% center no-repeat;
					background-size: 0px;
					color: $white;
					&:hover {
						background-size: 200%;
					}
				}
				&.active {
					> .menu_link { background-image: none; }
				}
			}

			> .menu_link {
				@include transform(scale(0.92));
				border-radius: 3px;
				background: theme(primary, base) url("/_/images/layout/hover-secondary.svg") 15% center no-repeat;				
				&:hover {
					@include transform(scale(1));
				}
			}

		}

	}

	.menu_link {
		@include transition(all ease-in-out 300ms);
		display: block;
		padding: 10px 15px 11px;
		@include font-size(14);
		font-weight: $body-weight-bold;
		letter-spacing: -0.02em;
		text-transform: uppercase;
		background-size: 0px !important;
		color: $white;
		&:hover {
			@include transition(all ease-in-out 500ms);
			background-size: 200% !important;
			background-position: center center !important;
		}	
		.caret { background-image: url("/_/images/icons/caret.png"); }	
	}

/*	.dropdown_menu {
		min-width: 0;
		padding: 10px;
		border: 1px solid theme(secondary, dark);
		border-top: none;
		box-shadow: 0 15px 10px -15px rgba(black, 0.6);
		background-color: theme(secondary, base);

		.menu_item:not(:last-child) { border-bottom: 1px solid rgba(black,0.28); }
		.menu_item:not(:first-child) { border-top: 1px solid rgba($white,0.31); }		

		.menu_link {
			padding: 13px 45px 13px 10px;
			background: transparent url("/_/images/layout/hover-primary.svg") 15% center no-repeat;
		}

	}*/

}
