.coverage {

	padding: 30px;

	.coverage_title {
		margin: 0 0 0.75rem;
		@include font-size(18);
		font-weight: $heading-weight-bolder;
		text-transform: uppercase;
		color: theme(secondary, base);
		a { text-decoration: none; }
	}

	p { 
		margin-bottom: 0.5rem;
		line-height: 1.325em;
		&:last-of-type { margin-bottom: 0; }
	}

		.coverage_county {
			@include font-size(15);
			font-weight: $body-weight-bold;
		}

		.coverage_city {
			@include font-size(15);
		}

}

.block.coverage {
	padding-bottom: 60px;
	text-align: center;
	box-shadow: none;
	border-top: 1px solid darken($body-bg, 7.5%);
	background-color: $white;

	.row { justify-content: center; }

 	@include media-breakpoint-up(lg) { .wrap { @include make-col(10); } }
	@include media-breakpoint-up(xl) { .wrap { @include make-col(9); } } 

	.page_front & {
		padding-top: 45px;
	}

}