$jumbo_bg:		'primary';
$jdark:			0.4;
$jlite:			0.2;

.block_jumbo {
	padding-left: 15px;
	padding-right: 15px;
	background-color: theme($jumbo_bg, light);
	background: linear-gradient( -45deg, transparent 15%, rgba($white, $jlite) 50%, transparent 85% ),
				linear-gradient( -150deg, rgba(black, $jdark) 20%, transparent 40%, transparent 60%, rgba(black, $jdark) 80% ),
				theme($jumbo_bg, light);
}

/*	.jumbo_conversion {
		text-align: center;
		color: $white;
		@include media-breakpoint-up(sm) {
			@include make-flex;
			align-items: center;
			justify-content: center;
			margin-top: 30px;
			h2 { margin: 0 15px 0 0; }
		}
	}*/